import { inject, observer } from "mobx-react";
import { ApplePayButton, canUseApplePay } from "./ApplePayButton";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { sanitizeCSS } from "../utils/sanitizeCss";
import { GooglePayButton } from "./GooglePayButton";
import { GooglePay } from "../modules/google-pay";

const ExpressCheckout = (props = {}) => {
  const appToken = process.env.REACT_APP_TOKEN; 
  const [domain, setDomain] = useState();
  const [paypoint, setPaypoint] = useState();
  const [config] = useState(JSON.parse(window.atob(props.match.params.config)));
  const [errorMessage, setErrorMessage] = useState();
  const [allowedMethods, setAllowedMethods] = useState({
    applePay: false,
    googlePay: false,
  });

  const resizeContainer = useCallback(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        window.parent.postMessage(
          {
            event: "callback-payabli-function-resize" + config.randomId,
            data: cr.height,
          },
          "*"
        );
      }
    });
    resizeObserver.observe(document.body);
  }, [config]);

  const getListDomains = useCallback(
    async (paypointID) => {
      const endpoint = `${process.env.REACT_APP_URL_API}PaymentMethodDomain/list?entityId=${paypointID}&entityType=paypoint`;
      return await axios.get(endpoint, {
        headers: {
          requestToken: appToken
        },
      });
    },
    [appToken]
  );

  const applePayScript = useCallback(
    () =>
      new Promise((resolve) => {
        const script = document.createElement("script");
        script.src =
          "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
        script.type = "text/javascript";
        script.async = true;
        document.head.appendChild(script);
        script.onload = () => {
          resolve();
          console.info("Apple Pay SDK loaded");
        };
      }),
    []
  );

  const getPaypoint = useCallback(
    async (entryname) => {
      const endpoint = `${process.env.REACT_APP_URL_API}Paypoint/basic/${entryname}`;
      return await axios.get(endpoint, {
        headers: {
          requestToken: appToken,
        },
      });
    },
    [appToken]
  );

  const setTokenUI = useCallback(
    (token) => {
      window.parent.postMessage(
        {
          event: "callback-payabli-function-set-token" + config.randomId,
          data: token,
        },
        "*"
      );
    },
    [config]
  );

  const isWalletActiveInSettings = useCallback((settingsData = {}, wallet) => {
    const walletsKeys = {
      applePay: "isApplePayEnabled",
      googlePay: "isGooglePayEnabled",
    };

    if (!wallet || !Object.keys(walletsKeys).includes(wallet))
      throw new Error("Wallet type not found");

    const walletSettings = settingsData?.forWallets?.find(
      (item) => item.key === walletsKeys[wallet]
    );
    if (walletSettings?.value === "false") {
      console.warn(`${wallet} is disabled in settings`);
      return false;
    }
    return true;
  }, []);

  const domainsByPaypoint = useCallback(async (entryPoint, idPaypoint) => {
    try {
      if (!entryPoint) throw new Error("Wallet Error: Entry point not found");
      if (!idPaypoint) throw new Error("Wallet Error: Paypoint ID not found");

      const domainsResponse = await getListDomains(idPaypoint);
      return domainsResponse?.data?.Records ?? [];
    } catch (error) {
      console.error(`Error getting domains by paypoint:`, error);
    }
  }, [getListDomains]);

  const isDomainActive = useCallback((domains = [], wallet, domain) => {
    return domains.some((d) => d[wallet]?.isEnabled && d.domainName === domain);
  }, []);

  const [googlePayInstance, setGooglePayInstance] = useState();
  const loadExpressCheckout = useCallback(
    async (
      [
        { data: tokenData = {} },
        { data: settingsData = {} },
        { data: paypointResponse = {} },
      ],
      config,
      domain
    ) => {
      new Promise(async (resolve, reject) => {
        try {
          if (!tokenData || !settingsData || !domain) {
            throw new Error("Token, settings or domain data not found");
          }
          const paypointId =
            paypointResponse?.responseData?.Paypoint?.IdPaypoint;
          setPaypoint(paypointResponse?.responseData?.Paypoint);
          const domains = await domainsByPaypoint(
            tokenData.entryPoint,
            paypointId
          );
          const allowedMethods = {};

          // Validate if the apple pay is enabled and the browser supports it
          if (canUseApplePay() && config?.expressCheckout?.applePay?.enabled) {
            // Check if Apple Pay is enabled at the paypoint level
            const hasSettings = isWalletActiveInSettings(
              settingsData,
              "applePay"
            );
            const hasDomain = isDomainActive(domains, "applePay", domain);
            if (hasSettings && hasDomain) {
              applePayScript();
              allowedMethods.applePay = true;
            }
          }

          if (config?.expressCheckout?.googlePay?.enabled) {
            const hasSettings = isWalletActiveInSettings(
              settingsData,
              "googlePay"
            );
            const hasDomain = isDomainActive(domains, "googlePay", domain);
            if (hasSettings && hasDomain) {
              const googlePay = new GooglePay({
                config,
                paypoint: paypointResponse.responseData,
                domain,
              });
              const res = await googlePay.loadScript();
              if (res.ok) {
                allowedMethods.googlePay = true;
                setGooglePayInstance(googlePay);
              }
            }
          }

          // Upload custom css
          if (config.customCssUrl) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.type = "text/css";
            link.href = config.customCssUrl;
            document.head.appendChild(link);
          }

          if (
            config.customCssStyle &&
            typeof config.customCssStyle === "string"
          ) {
            const css = sanitizeCSS(config.customCssStyle);
            const style = document.createElement("style");
            style.type = "text/css";
            style.appendChild(document.createTextNode(css));
            document.head.appendChild(style);
          }

          // Resize container
          resizeContainer();

          // Update vTerminal
          props.vTerminal.setEntryPoint(tokenData.entryPoint);
          props.vTerminal.setToken(tokenData.identifier);

          // Set token UI
          setTokenUI(tokenData.identifier);
          return resolve(allowedMethods);
        } catch (error) {
          console.error(error);
          setErrorMessage(
            error.message ||
              "An error occurred while initializing the component."
          );
          return reject(error);
        }
      }).then((allowedMethods) => {
        // Send ready event
        window.parent.postMessage(
          {
            event:
              "callback-payabli-function-mounted-component" + config.randomId,
            data: true,
          },
          "*"
        );
        props.global.setLoading(false);
        setAllowedMethods(allowedMethods);
        if (Object.values(allowedMethods).some((method) => method)) {
          props.global.setReady(true);
          window.parent.postMessage(
            {
              event:
                "callback-payabli-function-expresscheckout-ready" +
                config.randomId,
              data: allowedMethods,
            },
            "*"
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, setTokenUI, resizeContainer, applePayScript]
  );

  const initialQuery = useCallback(async () => {
    if (!config?.entryPoint || config?.entryPoint?.length === 0) {
      setErrorMessage("Entry point not found");
      return;
    }
    const murl = `Tools/init/method/${config.entryPoint}`;
    const tokenPromise = axios.get(process.env.REACT_APP_URL_API + murl, {
      headers: {
        requestToken: config.token,
      },
    });
    const settingsPromise = axios.get(
      process.env.REACT_APP_URL_API + `Paypoint/settings/${config.entryPoint}`,
      {
        headers: {
          requestToken: appToken,
        },
      }
    );

    const paypointResponse = await getPaypoint(config.entryPoint);

    return await Promise.all([tokenPromise, settingsPromise, paypointResponse]);
  }, [config, appToken, getPaypoint]);

  useEffect(() => {
    if (domain) {
      // Check if any service is enabled
      const hasServiceEnabled = Object.values(
        config.expressCheckout || {}
      ).some((service) => service.enabled);
      if (hasServiceEnabled) {
        initialQuery().then((response) => {
          loadExpressCheckout(response, config, domain);
        });
      }
    }
  }, [config, initialQuery, loadExpressCheckout, domain]);

  const handleMessage = useCallback(
    (event) => {
      if (
        event.data.event ===
        `callback-payabli-function-set-domain${config.randomId}`
      ) {
        if (event.data.data) {
          setDomain(event.data.data);
        }
      }
    },
    [config]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    window.parent.postMessage(
      {
        event: "callback-payabli-function-init-domain" + config.randomId,
      },
      "*"
    );
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [config, handleMessage]);

  if (errorMessage) {
    return <p className="small text-center">{errorMessage}</p>;
  }

  const atleast2Wallets =
    Object.values(allowedMethods).filter((method) => method).length > 1;
  const columns = atleast2Wallets ? config?.expressCheckout?.columns : 1;
  return (
    <>
      <div
        style={{
          display: "grid",
          position: "relative",
          gap: "1rem",
          padding: "0.25rem",
          maxWidth: "none",
          gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
        }}
        className="container w-100"
      >
        {/* Apple pay button */}
        {allowedMethods.applePay && (
          <ApplePayButton
            paypoint={paypoint}
            domain={domain}
            vTerminal={props.vTerminal}
            config={config}
            enabled={allowedMethods.applePay}
          />
        )}
        {/* Google pay button */}
        {allowedMethods.googlePay && googlePayInstance && (
          <GooglePayButton instance={googlePayInstance} config={config} />
        )}
      </div>
    </>
  );
};
export default inject("vTerminal", "global")(observer(ExpressCheckout));
