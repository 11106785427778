import React from 'react';
import { PaymentMethodECheck } from '../components/PaymentMethodECheck';
import { PaymentMethodCCard } from '../components/PaymentMethodCCard';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import '../assets/css/customEmbeded.css';
import { sanitizeCSS } from '../utils/sanitizeCss';

@inject('global' , 'vTerminal')
@observer
class PaymentMethodEmbeded extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initToken: null,
            errorMessage: null,
            config: null,
            configCustomCss: null,
            customCssStyle: null,
            clearFormAfterSubmit: true,
            showPopoverError: true
        };

        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.init = this.init.bind(this);
        this.functionCallBackSuccess = this.functionCallBackSuccess.bind(this);
        this.functionCallBackError = this.functionCallBackError.bind(this);
        this.resizeContainer = this.resizeContainer.bind(this);
    }


    init(res, config){  
        config && this.props.vTerminal.setConfig(config);
        if(res.data && res.data.ach && res.data.card && res.data.identifier){
            this.props.vTerminal.setPaymentMethodStatus('eCheck', typeof config.ach.enabled === 'undefined' ? res.data.ach.enabled : config.ach.enabled && res.data.ach.enabled );
            this.props.vTerminal.setPaymentMethodStatus('checking', typeof config.ach.checking === 'undefined' ? res.data.ach.checking : config.ach.checking && res.data.ach.checking );
            this.props.vTerminal.setPaymentMethodStatus('savings', typeof config.ach.savings === 'undefined' ? res.data.ach.savings : config.ach.savings && res.data.ach.savings);
            
            if(res.data.card.enabled === false || config.card.enabled === false){
                this.props.vTerminal.setPaymentMethodStatus('visa', false);
                this.props.vTerminal.setPaymentMethodStatus('mastercard', false);
                this.props.vTerminal.setPaymentMethodStatus('discover', false);
                this.props.vTerminal.setPaymentMethodStatus('amex', false);
                this.props.vTerminal.setPaymentMethodStatus('diners', false);
                this.props.vTerminal.setPaymentMethodStatus('jcb', false);
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("1");
            }else{
                
                if(typeof res.data.card.jcb === "undefined"){
                    res.data.card.jcb = true;
                }
                if(typeof res.data.card.diners === "undefined"){
                    res.data.card.diners = true;
                }
                if(typeof res.data.card.visa === "undefined"){
                    res.data.card.visa = true;
                }
                if(typeof res.data.card.mastercard === "undefined"){
                    res.data.card.mastercard = true;
                }
                if(typeof res.data.card.discover === "undefined"){
                    res.data.card.discover = true;
                }
                if(typeof res.data.card.amex === "undefined"){
                    res.data.card.amex = true;
                }

                this.props.vTerminal.setPaymentMethodStatus('visa', config.card.visa && res.data.card.visa);
                this.props.vTerminal.setPaymentMethodStatus('mastercard', config.card.mastercard && res.data.card.mastercard);
                this.props.vTerminal.setPaymentMethodStatus('discover', config.card.discover && res.data.card.discover);
                this.props.vTerminal.setPaymentMethodStatus('amex', config.card.amex && res.data.card.amex);
                this.props.vTerminal.setPaymentMethodStatus('diners', config.card.diners && res.data.card.diners);
                this.props.vTerminal.setPaymentMethodStatus('jcb', config.card.jcb && res.data.card.jcb);
            }

            if(res.data.card.enabled === false && res.data.ach.enabled === false){
                this.setState({errorMessage: "Sorry, you don't have credentials to make a payment."});
            }
            //merge config with achInputs
            if (config !== null && config.ach !== null && config.ach.inputs && config.ach.inputs !== null) {
                let dr = this.props.vTerminal.achInputs;
                let or = config.ach.inputs;
                let n=0;
                let fn=true;
                if (or.achAccountHolderName && or.achAccountHolderName !== null) {
                    if (or.achAccountHolderName.label) {
                        dr.achAccountHolderName.label = or.achAccountHolderName.label;
                    }
                    if (or.achAccountHolderName.placeholder) {
                        dr.achAccountHolderName.placeholder = or.achAccountHolderName.placeholder;
                    }
                    if (or.achAccountHolderName.hasOwnProperty('size')) {
                        n = parseInt(or.achAccountHolderName.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        dr.achAccountHolderName.size = n;
                    }
					if (or.achAccountHolderName.hasOwnProperty('value')) {
						if(or.achAccountHolderName.value.length>0){
							dr.achAccountHolderName.value = or.achAccountHolderName.value;
							this.props.vTerminal.handleTextChangeACH('achAccountHolderName', or.achAccountHolderName.value);
						}
                    }
                    if (or.achAccountHolderName.hasOwnProperty('floating')) {
                        fn = Boolean(or.achAccountHolderName.floating);
                        dr.achAccountHolderName.floating = fn;
                    }
                    if (or.achAccountHolderName.hasOwnProperty('row')) {
                        n = parseInt(or.achAccountHolderName.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        dr.achAccountHolderName.row = n;
                    }
                    if (or.achAccountHolderName.hasOwnProperty('order')) {
                        n = parseInt(or.achAccountHolderName.order, 10);
                        if (n < 0) n = 0;
                        dr.achAccountHolderName.order = n;
                    }
                }
                if (or.achAccountType && or.achAccountType !== null) {
                    if (or.achAccountType.label) {
                        dr.achAccountType.label = or.achAccountType.label;
                    }
                    if (or.achAccountType.placeholder) {
                        dr.achAccountType.placeholder = or.achAccountType.placeholder;
                    }
					if (or.achAccountType.hasOwnProperty('value')) {
						if(or.achAccountType.value.length>0){
							dr.achAccountType.value = or.achAccountType.value;
							this.props.vTerminal.handleTextChangeACH('achAccountType', or.achAccountType.value);
						}
                    }
                    if (or.achAccountType.hasOwnProperty('size')) {
                        n = parseInt(or.achAccountType.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        dr.achAccountType.size = n;
                    }
                    if (or.achAccountType.hasOwnProperty('floating')) {
                        fn = Boolean(or.achAccountType.floating);
                        dr.achAccountType.floating = fn;
                    }
                    if (or.achAccountType.hasOwnProperty('row')) {
                        n = parseInt(or.achAccountType.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        dr.achAccountType.row = n;
                    }
                    if (or.achAccountType.hasOwnProperty('order')) {
                        n = parseInt(or.achAccountType.order, 10);
                        if (n < 0) n = 0;
                        dr.achAccountType.order = n;
                    }
                }
                if (or.achRouting && or.achRouting !== null) {
                    if (or.achRouting.label) {
                        dr.achRouting.label = or.achRouting.label;
                    }
                    if (or.achRouting.placeholder) {
                        dr.achRouting.placeholder = or.achRouting.placeholder;
                    }
					if (or.achRouting.hasOwnProperty('value')) {
						if(or.achRouting.value.length>0){
							dr.achRouting.value = or.achRouting.value;
							this.props.vTerminal.handleTextChangeACH('achRouting', or.achRouting.value);
						}
                    }
                    if (or.achRouting.hasOwnProperty('size')) {
                        n = parseInt(or.achRouting.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        dr.achRouting.size = n;
                    }
                    if (or.achRouting.hasOwnProperty('floating')) {
                        fn = Boolean(or.achRouting.floating);
                        dr.achRouting.floating = fn;
                    }
                    if (or.achRouting.hasOwnProperty('row')) {
                        n = parseInt(or.achRouting.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        dr.achRouting.row = n;
                    }
                    if (or.achRouting.hasOwnProperty('order')) {
                        n = parseInt(or.achRouting.order, 10);
                        if (n < 0) n = 0;
                        dr.achRouting.order = n;
                    }
                    if (or.achRouting.hasOwnProperty('confirm')) {
                        dr.achRouting.confirm = or.achRouting.confirm;
                    }
                }
                if (or.achAccount && or.achAccount !== null) {
                    if (or.achAccount.label) {
                        dr.achAccount.label = or.achAccount.label;
                    }
                    if (or.achAccount.placeholder) {
                        dr.achAccount.placeholder = or.achAccount.placeholder;
                    }
					if (or.achAccount.hasOwnProperty('value')) {
						if(or.achAccount.value.length>0){
							dr.achAccount.value = or.achAccount.value;
							this.props.vTerminal.handleTextChangeACH('achAccount', or.achAccount.value);
						}

                    }
                    if (or.achAccount.hasOwnProperty('size')) {
                        n = parseInt(or.achAccount.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        dr.achAccount.size = n;
                    }
                    if (or.achAccount.hasOwnProperty('floating')) {
                        fn = Boolean(or.achAccount.floating);
                        dr.achAccount.floating = fn;
                    }
                    if (or.achAccount.hasOwnProperty('row')) {
                        n = parseInt(or.achAccount.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        dr.achAccount.row = n;
                    }
                    if (or.achAccount.hasOwnProperty('order')) {
                        n = parseInt(or.achAccount.order, 10);
                        if (n < 0) n = 0;
                        dr.achAccount.order = n;
                    }
                    if (or.achAccount.hasOwnProperty('confirm')) {
                        dr.achAccount.confirm = or.achAccount.confirm;
                    }
                }
                //prepare the rows for ach
                let ac = this.props.vTerminal.achRows;
                ac = [[], [], []];
                ac[dr.achAccountHolderName.row].push("achAccountHolderName");
                if (dr.achAccountType.row === dr.achAccountHolderName.row) {
                    if (dr.achAccountType.order < dr.achAccountHolderName.order) {
                        ac[dr.achAccountType.row].unshift("achAccountType");
                    }
                    else {
                        ac[dr.achAccountType.row].push("achAccountType");
                    }
                }
                else {
                    ac[dr.achAccountType.row].push("achAccountType");
                }
                if (ac[dr.achRouting.row].length > 0) {
                        if (ac[dr.achRouting.row][0] === "achAccountType") {
                            if (dr.achAccountType.order > dr.achRouting.order) {
                                ac[dr.achRouting.row].unshift("achRouting");
                            }
                            else {
                                if (ac[dr.achRouting.row].length > 1) {
                                    if (dr.achAccountHolderName.order > dr.achRouting.order) {
                                        ac[dr.achRouting.row].splice(1, 0, "achRouting");
                                    }
                                    else {
                                        ac[dr.achRouting.row].push("achRouting");
                                    }
                                }
                                else {
                                    ac[dr.achRouting.row].push("achRouting");
                                }
                            }
                        }
                        else {
                            if (dr.achAccountHolderName.order > dr.achRouting.order) {
                                ac[dr.achRouting.row].unshift("achRouting");
                            }
                            else {
                                if (ac[dr.achRouting.row].length > 1) {
                                    if (dr.achAccountType.order > dr.achRouting.order) {
                                        ac[dr.achRouting.row].splice(1, 0, "achRouting");
                                    }
                                    else {
                                        ac[dr.achRouting.row].push("achRouting");
                                    }
                                }
                                else {
                                    ac[dr.achRouting.row].push("achRouting");
                                }
                            }
                        }
                }
                else {
                    ac[dr.achRouting.row].push("achRouting");
                }
                if (ac[dr.achAccount.row].length > 0) {
                    for (let j = ac[dr.achAccount.row].length - 1; j >= 0; j--) {
                        if (ac[dr.achAccount.row][j] === "achAccountHolderName") {
                            if (dr.achAccount.order < dr.achAccountHolderName.order) {
                                ac[dr.achAccount.row].splice(j, 0, "achAccount");
                                break;
                            }
                            else {
                                if (j === (ac[dr.achAccount.row].length - 1)) {
                                    ac[dr.achAccount.row].push("achAccount");
                                    break;
                                }
                            }
                        }
                        else if (ac[dr.achAccount.row][j] === "achAccountType") {
                            if (dr.achAccount.order < dr.achAccountType.order) {
                                ac[dr.achAccount.row].splice(j, 0, "achAccount");
                                break;
                            }
                            else {
                                if (j === (ac[dr.achAccount.row].length - 1)) {
                                    ac[dr.achAccount.row].push("achAccount");
                                    break;
                                }
                            }
                        }
                        else if (ac[dr.achAccount.row][j] === "achRouting") {
                            if (dr.achAccount.order < dr.achRouting.order) {
                                ac[dr.achAccount.row].splice(j, 0, "achAccount");
                                break;
                            }
                            else {
                                if (j === (ac[dr.achAccount.row].length - 1)) {
                                    ac[dr.achAccount.row].push("achAccount");
                                    break;
                                }
                            }
                        }
                    }
                }
                else {
                    ac[dr.achAccount.row].push("achAccount");
                }
                this.props.vTerminal.setAchArray(ac);
            }

            //merge config with cardInputs
            if (config !== null && config.card !== null && config.card.inputs && config.card.inputs !== null) {
                let drr = this.props.vTerminal.cardInputs;
                let orr = config.card.inputs;
                let n=0;
                let fn=true;
                if (orr.cardHolderName && orr.cardHolderName !== null) {
                    if (orr.cardHolderName.label) {
                        drr.cardHolderName.label = orr.cardHolderName.label;
                    }
                    if (orr.cardHolderName.placeholder) {
                        drr.cardHolderName.placeholder = orr.cardHolderName.placeholder;
                    }
					if (orr.cardHolderName.hasOwnProperty('value')) {
						if(orr.cardHolderName.value.length>0){
							drr.cardHolderName.value = orr.cardHolderName.value;
							this.props.vTerminal.handleTextChangeACH('cardHolderName', orr.cardHolderName.value);
						}
                    }
                    if (orr.cardHolderName.hasOwnProperty('size')) {
                        n = parseInt(orr.cardHolderName.size, 10);
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        drr.cardHolderName.size = n;
                    }
                    if (orr.cardHolderName.hasOwnProperty('floating')) {
                        fn = Boolean(orr.cardHolderName.floating);
                        drr.cardHolderName.floating = fn;
                    }
                    if (orr.cardHolderName.hasOwnProperty('row')) {
                        n = parseInt(orr.cardHolderName.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        drr.cardHolderName.row = n;
                    }
                    if (orr.cardHolderName.hasOwnProperty('order')) {
                        n = parseInt(orr.cardHolderName.order, 10);
                        if (n < 0) n = 0;
                        drr.cardHolderName.order = n;
                    }
                }
                if (orr.cardNumber && orr.cardNumber !== null) {
                    if (orr.cardNumber.label) {
                        drr.cardNumber.label = orr.cardNumber.label;
                    }
                    if (orr.cardNumber.placeholder) {
                        drr.cardNumber.placeholder = orr.cardNumber.placeholder;
                    }
					if (orr.cardNumber.hasOwnProperty('value')) {
						if(orr.cardNumber.value.length>0){
							drr.cardNumber.value = orr.cardNumber.value;
							this.props.vTerminal.handleTextChangeACH('cardNumber', orr.cardNumber.value);
						}
                    }
                    if (orr.cardNumber.hasOwnProperty('size')) {
                        n = parseInt(orr.cardNumber.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        drr.cardNumber.size = n;
                    }
                    if (orr.cardNumber.hasOwnProperty('floating')) {
                        fn = Boolean(orr.cardNumber.floating);
                        drr.cardNumber.floating = fn;
                    }
                    if (orr.cardNumber.hasOwnProperty('row')) {
                        n = parseInt(orr.cardNumber.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        drr.cardNumber.row = n;
                    }
                    if (orr.cardNumber.hasOwnProperty('order')) {
                        n = parseInt(orr.cardNumber.order, 10);
                        if (n < 0) n = 0;
                        drr.cardNumber.order = n;
                    }
                }
                if (orr.cardExpirationDate && orr.cardExpirationDate !== null) {
                    if (orr.cardExpirationDate.label) {
                        drr.cardExpirationDate.label = orr.cardExpirationDate.label;
                    }
                    if (orr.cardExpirationDate.placeholder) {
                        drr.cardExpirationDate.placeholder = orr.cardExpirationDate.placeholder;
                    }
					if (orr.cardExpirationDate.hasOwnProperty('value')) {
						if(orr.cardExpirationDate.value.length>0){
							drr.cardExpirationDate.value = orr.cardExpirationDate.value;
							this.props.vTerminal.handleTextChangeACH('cardExpirationDate', orr.cardExpirationDate.value);
						}
                    }
                    if (orr.cardExpirationDate.hasOwnProperty('size')) {
                        n = parseInt(orr.cardExpirationDate.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        drr.cardExpirationDate.size = n;
                    }
                    if (orr.cardExpirationDate.hasOwnProperty('floating')) {
                        fn = Boolean(orr.cardExpirationDate.floating);
                        drr.cardExpirationDate.floating = fn;
                    }
                    if (orr.cardExpirationDate.hasOwnProperty('row')) {
                        n = parseInt(orr.cardExpirationDate.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        drr.cardExpirationDate.row = n;
                    }
                    if (orr.cardExpirationDate.hasOwnProperty('order')) {
                        n = parseInt(orr.cardExpirationDate.order, 10);
                        if (n < 0) n = 0;
                        drr.cardExpirationDate.order = n;
                    }
                }
                if (orr.cardCvv && orr.cardCvv !== null) {
                    if (orr.cardCvv.label) {
                        drr.cardCvv.label = orr.cardCvv.label;
                    }
                    if (orr.cardCvv.placeholder) {
                        drr.cardCvv.placeholder = orr.cardCvv.placeholder;
                    }
					if (orr.cardCvv.hasOwnProperty('value')) {
						if(orr.cardCvv.value.length>0){
							drr.cardCvv.value = orr.cardCvv.value;
							this.props.vTerminal.handleTextChangeACH('cardCvv', orr.cardCvv.value);
						}
                    }
                    if (orr.cardCvv.hasOwnProperty('size')) {
                        n = parseInt(orr.cardCvv.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        drr.cardCvv.size = n;
                    }
                    if (orr.cardCvv.hasOwnProperty('floating')) {
                        fn = Boolean(orr.cardCvv.floating);
                        drr.cardCvv.floating = fn;
                    }
                    if (orr.cardCvv.hasOwnProperty('row')) {
                        n = parseInt(orr.cardCvv.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        drr.cardCvv.row = n;
                    }
                    if (orr.cardCvv.hasOwnProperty('order')) {
                        n = parseInt(orr.cardCvv.order, 10);
                        if (n < 0) n = 0;
                        drr.cardCvv.order = n;
                    }
                }
                if (orr.cardZipcode && orr.cardZipcode !== null) {
                    if (orr.cardZipcode.label) {
                        drr.cardZipcode.label = orr.cardZipcode.label;
                    }
                    if (orr.cardZipcode.placeholder) {
                        drr.cardZipcode.placeholder = orr.cardZipcode.placeholder;
                    }
					if (orr.cardZipcode.hasOwnProperty('value')) {
						if(orr.cardZipcode.value.length>0){
							drr.cardZipcode.value = orr.cardZipcode.value;
							this.props.vTerminal.handleTextChangeACH('cardZipcode', orr.cardZipcode.value);
						}
                    }
                    if (orr.cardZipcode.hasOwnProperty('size')) {
                        n = parseInt(orr.cardZipcode.size, 10) ;
                        if (n > 12) n = 12;
                        if (n < 1) n = 1;
                        drr.cardZipcode.size = n;
                    }
                    if (orr.cardZipcode.hasOwnProperty('floating')) {
                        fn = Boolean(orr.cardZipcode.floating);
                        drr.cardZipcode.floating = fn;
                    }
                    if (orr.cardZipcode.hasOwnProperty('row')) {
                        n = parseInt(orr.cardZipcode.row, 10);
                        if (n > 2) n = 2;
                        if (n < 0) n = 0;
                        drr.cardZipcode.row = n;
                    }
                    if (orr.cardZipcode.hasOwnProperty('order')) {
                        n = parseInt(orr.cardZipcode.order, 10);
                        if (n < 0) n = 0;
                        drr.cardZipcode.order = n;
                    }
                }
                //prepare the rows for cards
                let acc = this.props.vTerminal.cardRows;
                acc = [[], [], []];
                acc[drr.cardHolderName.row].push("cardHolderName");
                if (drr.cardNumber.row === drr.cardHolderName.row) {
                    if (drr.cardNumber.order < drr.cardHolderName.order) {
                        acc[drr.cardNumber.row].unshift("cardNumber");
                    }
                    else {
                        acc[drr.cardNumber.row].push("cardNumber");
                    }
                }
                else {
                    acc[drr.cardNumber.row].push("cardNumber");
                }
                if (acc[drr.cardExpirationDate.row].length > 0) {
                    for (let jj = acc[drr.cardExpirationDate.row].length - 1; jj >= 0; jj--) {
                        if (acc[drr.cardExpirationDate.row][jj] === "cardHolderName") {
                            if (drr.cardExpirationDate.order < drr.cardHolderName.order) {
                                acc[drr.cardExpirationDate.row].splice(jj, 0, "cardExpirationDate");
                                break;
                            }
                            else {
                                if (jj === (acc[drr.cardExpirationDate.row].length - 1)) {
                                    acc[drr.cardExpirationDate.row].push("cardExpirationDate");
                                    break;
                                }
                            }
                        }
                        else if (acc[drr.cardExpirationDate.row][jj] === "cardNumber") {
                            if (drr.cardExpirationDate.order < drr.cardNumber.order) {
                                acc[drr.cardExpirationDate.row].splice(jj, 0, "cardExpirationDate");
                                break;
                            }
                            else {
                                if (jj === (acc[drr.cardExpirationDate.row].length - 1)) {
                                    acc[drr.cardExpirationDate.row].push("cardExpirationDate");
                                    break;
                                }
                            }
                        }
                    }
                }
                else {
                    acc[drr.cardExpirationDate.row].push("cardExpirationDate");
                }
                if (acc[drr.cardCvv.row].length > 0) {
                    for (var j = acc[drr.cardCvv.row].length - 1; j >= 0; j--) {
                        if (acc[drr.cardCvv.row][j] === "cardHolderName") {
                            if (drr.cardCvv.order < drr.cardHolderName.order) {
                                acc[drr.cardCvv.row].splice(j, 0, "cardCvv");
                                break;
                            }
                            else {
                                if (j === (acc[drr.cardCvv.row].length - 1)) {
                                    acc[drr.cardCvv.row].push("cardCvv");
                                    break;
                                }
                            }
                        }
                        else if (acc[drr.cardCvv.row][j] === "cardNumber") {
                            if (drr.cardCvv.order < drr.cardNumber.order) {
                                acc[drr.cardCvv.row].splice(j, 0, "cardCvv");
                                break;
                            }
                            else {
                                if (j === (acc[drr.cardCvv.row].length - 1)) {
                                    acc[drr.cardCvv.row].push("cardCvv");
                                    break;
                                }
                            }
                        }
                        else if (acc[drr.cardCvv.row][j] === "cardExpirationDate") {
                            if (drr.cardCvv.order < drr.cardExpirationDate.order) {
                                acc[drr.cardCvv.row].splice(j, 0, "cardCvv");
                                break;
                            }
                            else {
                                if (j === (acc[drr.cardCvv.row].length - 1)) {
                                    acc[drr.cardCvv.row].push("cardCvv");
                                    break;
                                }
                            }
                        }
                    }
                }
                else {
                    acc[drr.cardCvv.row].push("cardCvv");
                }
                if (acc[drr.cardZipcode.row].length > 0) {
                    for (var jx = acc[drr.cardZipcode.row].length - 1; jx >= 0; jx--) {
                        if (acc[drr.cardZipcode.row][jx] === "cardHolderName") {
                            if (drr.cardZipcode.order < drr.cardHolderName.order) {
                                acc[drr.cardZipcode.row].splice(jx, 0, "cardZipcode");
                                break;
                            }
                            else {
                                if (jx === (acc[drr.cardZipcode.row].length - 1)) {
                                    acc[drr.cardZipcode.row].push("cardZipcode");
                                    break;
                                }
                            }
                        }
                        else if (acc[drr.cardZipcode.row][jx] === "cardNumber") {
                            if (drr.cardZipcode.order < drr.cardNumber.order) {
                                acc[drr.cardZipcode.row].splice(jx, 0, "cardZipcode");
                                break;
                            }
                            else {
                                if (jx === (acc[drr.cardZipcode.row].length - 1)) {
                                    acc[drr.cardZipcode.row].push("cardZipcode");
                                    break;
                                }
                            }
                        }
                        else if (acc[drr.cardZipcode.row][jx] === "cardExpirationDate") {
                            if (drr.cardZipcode.order < drr.cardExpirationDate.order) {
                                acc[drr.cardZipcode.row].splice(jx, 0, "cardZipcode");
                                break;
                            }
                            else {
                                if (jx === (acc[drr.cardZipcode.row].length - 1)) {
                                    acc[drr.cardZipcode.row].push("cardZipcode");
                                    break;
                                }
                            }
                        }
                        else if (acc[drr.cardZipcode.row][jx] === "cardCvv") {
                            if (drr.cardZipcode.order < drr.cardCvv.order) {
                                acc[drr.cardZipcode.row].splice(jx, 0, "cardZipcode");
                                break;
                            }
                            else {
                                if (jx === (acc[drr.cardZipcode.row].length - 1)) {
                                    acc[drr.cardZipcode.row].push("cardZipcode");
                                    break;
                                }
                            }
                        }
                    }
                }
                else {
                    acc[drr.cardZipcode.row].push("cardZipcode");
                }
                this.props.vTerminal.setCardArray(acc);
            }

            if (config !== null && config.hasOwnProperty('customerData') && config.customerData !== null && Object.keys(config.customerData).length > 0) {
                this.props.vTerminal.setCustomerData(config.customerData);
            }
            if (config !== null && config.hasOwnProperty('paymentDetails') && config.paymentDetails !== null && Object.keys(config.paymentDetails).length > 0) {
                this.props.vTerminal.setPaymentDetails(config.paymentDetails);
            }
            
            this.props.vTerminal.setToken(res.data.identifier);
            this.props.vTerminal.setEntryPoint(res.data.entryPoint);
            let reactObj = this;
            setTimeout(function(){
                reactObj.props.global.setLoading(false);
                reactObj.props.global.setReady(true);
                reactObj.resizeContainer();
                reactObj.setTokenUI(res.data.identifier)
            }, 1000);
        }
        else{
            this.setState({errorMessage: "The data format from server is incorrect.", initToken: null});
        }
    }
    

    componentDidMount(){
        this.props.global.setLoading(true);
        let config = JSON.parse(window.atob(this.props.match.params.config));

        this.setState({config: config});
        window.addEventListener("message", (event) => {
            if (event.data.data && event.data.data !== null && Object.keys(event.data.data).length > 0) {
                if (event.data.data.customerData && event.data.data.customerData !== null && Object.keys(event.data.data.customerData).length > 0) {
                    this.props.vTerminal.setCustomerData(event.data.data.customerData);
                }
                if (event.data.data.paymentDetails && event.data.data.paymentDetails !== null && Object.keys(event.data.data.paymentDetails).length > 0) {
                    this.props.vTerminal.setPaymentDetails(event.data.data.paymentDetails);
                }
            }
            if(event.data.event === "callback-payabli-function-exec"+config.randomId){
                this.sendForm();
            }
            else if (event.data.event === "callback-payabli-function-pay"+config.randomId) {
                this.sendPay();
            }
            else if (event.data.event === "callback-payabli-function-auth"+config.randomId) {
                // true parameter for authorization
                this.sendPay(true);
            }
            else if (event.data.event === "callback-payabli-function-reinit"+config.randomId) {
                window.location.reload();
            }
        }, false);
        
        try{
            
            if(config.customCssUrl){
                this.setState({
                    configCustomCss: config.customCssUrl
                });
            }

            if(config.customCssStyle && typeof config.customCssStyle === 'string'){
                const css = sanitizeCSS(config.customCssStyle)
                this.setState({
                    customCssStyle: css
                });
            }

            if(config.clearFormAfterSubmit === false){
                this.setState({
                    clearFormAfterSubmit: false
                });
            }
            
            if(config.showPopoverError === false){
                this.setState({
                    showPopoverError: false
                });
            }

            if(config.defaultOpen){
                this.props.vTerminal.setDefaultPaymentMethodActiveKey(config.defaultOpen.toLowerCase() === 'card' ? "0" : "1");
                this.props.vTerminal.setPaymentMethod(config.defaultOpen.toLowerCase());
            }
            else{
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
            }

            if(config.forceCustomerCreation === false){
                this.props.vTerminal.setForceCustomerCreation(false);
            }
            
            if(config.temporaryToken === false){
                this.props.vTerminal.setTemporaryToken(false);
            }

            if(config && config.token){
                this.setState({token: config.token, errorMessage : null});
                
                var murl = 'Tools/init/method';
                if (config.entryPoint && config.entryPoint.length > 0) {
                    murl = murl + '/' + config.entryPoint;
                }

                setTimeout(function(){
                    window.parent.postMessage({
                        event: "callback-payabli-function-mounted-component"+config.randomId,
                        data: true
                    }, '*');
                }, 100);

                axios.get(process.env.REACT_APP_URL_API+ murl,{
                headers: {
                    'requestToken': config.token,
                }
                })
                .then(res => {
                    this.setState({config: config}, function(){
                        this.init(res, config);
                    });
                    
                })
                .catch(error => {
                    console.error(error)
                    this.setState({errorMessage: error.message});
                    this.props.global.setLoading(false);
                    throw error;
                });
            }
            else{
                this.setState({errorMessage: "'token' is required", initToken: null});
            }  

        }catch(error){
            this.setState({errorMessage: error.message, initToken: null});
        }

        this.resizeContainer();
        window.addEventListener('resize', this.resizeContainer);
        
    }

    resizeContainer(){
        let config = JSON.parse(window.atob(this.props.match.params.config));
        window.parent.postMessage({
            event: "callback-payabli-function-resize"+config.randomId,
            data: document.body.scrollHeight
        }, '*');
    }

    setTokenUI(token){
        let config = JSON.parse(window.atob(this.props.match.params.config));
        window.parent.postMessage({
            event: "callback-payabli-function-set-token"+config.randomId,
            data: token
        }, '*');
    }

    functionCallBackSuccess(data){
        let config = JSON.parse(window.atob(this.props.match.params.config));
        window.parent.postMessage({
            event: "callback-payabli-function-done"+config.randomId,
            data: data
        }, '*');
    }
    
    functionCallBackError(data){
        let config = JSON.parse(window.atob(this.props.match.params.config));
        window.parent.postMessage({
            event: "callback-payabli-function-error"+config.randomId,
            data: data
        }, '*');
    }

    functionCallBackReady() {
        this.validateFields();
        if (!this.props.vTerminal.hasPaymentPageErrors()) {
            let config = JSON.parse(window.atob(this.props.match.params.config));
            window.parent.postMessage({
                event: "callback-payabli-function-ready"+config.randomId,
                data: true
            }, '*');
        }
    }

    setPaymentMethod(method, activeKey){
        this.props.vTerminal.setPaymentMethod(method);
        if(activeKey!==null){
            this.props.vTerminal.setDefaultPaymentMethodActiveKey(activeKey);
        }
    }

    sendForm(){
        this.validateFields();
        if (!this.props.vTerminal.hasPaymentPageErrors()) {
            this.props.global.setLoading(true);
            const isValidCustomerData = this.props.vTerminal.forceCustomerCreation ? this.props.vTerminal.customerData !== null && Object.keys(this.props.vTerminal.customerData).length >= 1 : true
            if (isValidCustomerData) {
                this.props.vTerminal.addPaymentMethod().then(res => {
                    
                    if(this.state.clearFormAfterSubmit === true){
                        this.props.vTerminal.clearACHForm();
                        this.props.vTerminal.clearCCForm();
                    }
                    
                    this.functionCallBackSuccess(res.data);
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    console.error(error)
                    if(this.state.clearFormAfterSubmit === true){
                        this.props.vTerminal.clearACHForm();
                        this.props.vTerminal.clearCCForm();
                    }

                    var customError = [];
                    if(error.response.status && error.response.data){
                        customError.push({ code: error.response.status, key: "apiError", text: error.response.data.responseText ? error.response.data.responseText : error.response.data })
                    }

                    this.functionCallBackError([customError.length > 0 ? customError : error]);
                    this.props.global.setLoading(false);
                });
            }
            else {
                this.functionCallBackError(["customerError"]);
                this.props.global.setLoading(false);
            }
        }
        else {
            this.functionCallBackError(this.props.vTerminal.getPageErrors());
            this.props.global.setLoading(false);
        }
    }

    sendPay(auth) {
        this.validateFields();
        if (!this.props.vTerminal.hasPaymentPageErrors()) {
            this.props.global.setLoading(true);
            if (this.props.vTerminal.customerData !== null && Object.keys(this.props.vTerminal.customerData).length >= 1 && this.props.vTerminal.paymentDetails !== null && Object.keys(this.props.vTerminal.paymentDetails).length >= 1) {
                this.props.vTerminal.makeEmbeddedPayment(auth).then(res => {
                    
                    if(this.state.clearFormAfterSubmit === true){
                        this.props.vTerminal.clearACHForm();
                        this.props.vTerminal.clearCCForm();
                    }

                    this.functionCallBackSuccess(res.data);
                    this.props.global.setLoading(false);
                })
                    .catch(error => {
                        console.error(error)
                        if(this.state.clearFormAfterSubmit === true){
                            this.props.vTerminal.clearACHForm();
                            this.props.vTerminal.clearCCForm();
                        }

                        var customError = [];
                        if(error.response.status && error.response.data){
                            customError.push({ code: error.response.status, key: "apiError", text: error.response.data.responseText ? error.response.data.responseText : error.response.data })
                        }

                        this.functionCallBackError([customError.length > 0 ? customError : error]);
                        this.props.global.setLoading(false);
                    });
            }
            else {
                var error = [];
                if (this.props.vTerminal.customerData === null || Object.keys(this.props.vTerminal.customerData).length < 1) {
                    error.push({ code: 701, key: "customerError", text: "customerData is undefined." })
                }
                if (this.props.vTerminal.paymentDetails === null || Object.keys(this.props.vTerminal.paymentDetails).length < 1) {
                    error.push({ code: 702, key: "paymentDetailsError", text: "paymentDetails is undefined." })
                }
                this.functionCallBackError(error);
                this.props.global.setLoading(false);
            }
        }
        else {
            this.functionCallBackError(this.props.vTerminal.getPageErrors());
            this.props.global.setLoading(false);
        }
    }

    validateFields(){
        
        var paymentPage = this.props.vTerminal.paymentPage;
        var validators = this.props.global.validators;
        this.props.vTerminal.clearPaymentPageError();

        
        // validating ach form
        if(this.props.vTerminal.getPaymentMethod === "ach"){
            
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) ||  validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName) || validators.stringValidator('alphanumericspaceslatin', paymentPage.paymentMethods.cardHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccountHolderName',"Your account holder name is invalid");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccountHolderName',null);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccountType',"Your account type is invalid");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccountType',null);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achRouting) || !this.props.global.isValidRouting(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('achRouting',"Your routing number is invalid");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achRouting',null);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccount) ||  validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccount',"Your account number is invalid");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccount',null);
            }

            if(this.props.vTerminal.achInputs && this.props.vTerminal.achInputs.achRouting && this.props.vTerminal.achInputs.achRouting.confirm && this.props.vTerminal.achInputs.achRouting.confirm === true)
            {
                if(paymentPage.paymentMethods.reAchRouting !== paymentPage.paymentMethods.achRouting){
                    this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchRoutingError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('reAchRouting',"The field doesn't match");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchRoutingError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('reAchRouting',null);
                }
            }
            
            if(this.props.vTerminal.achInputs && this.props.vTerminal.achInputs.achAccount && this.props.vTerminal.achInputs.achAccount.confirm && this.props.vTerminal.achInputs.achAccount.confirm === true)
            {
                if(paymentPage.paymentMethods.reAchAccount !== paymentPage.paymentMethods.achAccount){
                    this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchAccountError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('reAchAccount',"The field doesn't match");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchAccountError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('reAchAccount',null);
                }
            }
           
            
        }else if(this.props.vTerminal.getPaymentMethod === "card"){

            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);
            let ccType = this.props.global.creditCardType(paymentPage.paymentMethods.cardNumber);

            let ccTypeAux = ccType;
            if(ccType === "american-express"){
                ccTypeAux  =  "amex";
            }
            if(ccType === "diners-club"){
                ccTypeAux  =  "diners";
            }
            if(ccTypeAux === "unknown" || validators.isEmpty(paymentPage.paymentMethods.cardNumber) || this.props.vTerminal.getCCTypeValidationsErrorCases() || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || this.state.config.card[ccTypeAux] === false)
            {
                if(ccTypeAux === "unknown"){
                    this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',"Your card number is invalid");
                }else{
                    this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',"Your card number is incomplete");
                }

                if(this.state.config.card[ccTypeAux] === false){
                    this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',`${this.props.global.getCardFullName(ccTypeAux)} not accepted`);
                }

                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',null);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is incomplete");
            }
            else{

                let expDateYear = paymentPage.paymentMethods.cardExpirationDate.substr(-2);
                let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(0,2);
                let currentYear =new Date().getFullYear().toString().substr(-2);
                let currentMonth =new Date().getMonth().toString();

                if(parseInt(expDateYear) < parseInt(currentYear)){
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is in the past");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',null);
                }


                if(((parseInt(expDateYear) === parseInt(currentYear)) && ((parseInt(expDateMonth) < parseInt(currentMonth)+1)  || (parseInt(expDateMonth) > 12)))){
                    if(parseInt(expDateMonth) > 12){
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is invalid");
                    }else{
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is in the past");
                    }
                }

            }

            let globalConfig = this.state.config;

            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardCvv && globalConfig.card.inputs.cardCvv.hidden !== true){
                if(validators.isEmpty(paymentPage.paymentMethods.cardCvv) || (ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv)))
                {
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardCvv',"Your card's security code is incomplete");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardCvv',null);
                }
            }

            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.hidden !== true){

                let zipCodeValidator = validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode);
                if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country && Array.isArray(globalConfig.card.inputs.cardZipcode.country)){
                    zipCodeValidator = !this.props.global.checkZipFormat(globalConfig.card.inputs.cardZipcode.country, paymentPage.paymentMethods.cardZipcode)
                }

                if(validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || zipCodeValidator)
                {
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',"Your card's zip code is incomplete or incorrect");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',null);
                }
            }
		
            
            if((globalConfig?.card?.inputs?.cardHolderName?.required === true ? validators.isEmpty(paymentPage.paymentMethods.cardHolderName) : false) || validators.isMaxLength(250, paymentPage.paymentMethods.cardHolderName) || validators.stringValidator('alphanumericspaceslatin', paymentPage.paymentMethods.cardHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('cardHolderName',"Your cardholder name is invalid");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('cardHolderName',null);
            }
			
        }

        this.resizeContainer();

    }

    render() {
        return (
            <>
            {this.props.global.isLoading &&
                <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                    <div id="main-spinner" className="spinner-border" role="status">
                    </div>
                </div>
            }

            {this.state.errorMessage ?
                this.state.errorMessage
            :
           
            <div className="payabliPaymentForm" style={{opacity: this.props.global.isReady ? 1 : 0}}>
                
                <div >
                { this.props.vTerminal.hasECheck && 
                <>
                    {this.props.vTerminal.defaultPaymentMethodActiveKey === "1" &&
                    <PaymentMethodECheck config={this.state.config} history={this.props.history} showPopoverError={this.state.showPopoverError} />
                    }
                </>
                }
                { this.props.vTerminal.hasCards  && 
                <>
                    {this.props.vTerminal.defaultPaymentMethodActiveKey === "0" &&
                    <PaymentMethodCCard config={this.state.config} history={this.props.history} showPopoverError={this.state.showPopoverError} />
                    }
                </>
                }
            </div>
            <p className="small text-center">{this.state.errorMessage}</p>   
            </div>
           
            }
                <Helmet>
                    {this.state.configCustomCss && <link rel="stylesheet" type='text/css' href={this.state.configCustomCss}/>}
                    {this.state.customCssStyle &&  <style type='text/css'>{this.state.customCssStyle}</style>}
                </Helmet>
            </>
           
        )
    }
}

export { PaymentMethodEmbeded };
