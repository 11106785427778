import { Helmet } from "react-helmet";
import { useEffect } from "react";

export const GooglePayButton = ({ config, instance }) => {
  const { expressCheckout = {} } = config || {};
  const { appearance = {} } = expressCheckout;

  useEffect(() => {
    instance.createButton();
  }, [instance]);

  return (
    <>
      <Helmet>
        <style type="text/css">{`
        #google-pay-button button {
          outline: transparent;
          min-width: 0;
        }
     `}</style>
      </Helmet>
      <div
        id="google-pay-button"
        style={{ height: appearance.buttonHeight || 44 }}
      ></div>
    </>
  );
};
